import React from "react";
import "./header.scss";
import Sitelogo from "../../assets/svg/logo.svg";
import CloseSVG from "../../assets/svg/closeSVG.js";
import { NavLink } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHideMenu: false,
      menuArray: [
        {
          title: "Home",
          routePath: "https://www.healthtunnel.com/",
        },
        {
          title: "Weight Loss Program",
          // routePath: `/category/Order-Medicine`//old
          // routePath: `/Order-Medicine`
          routePath: `https://www.surefit.health/`,
        },
        {
          title: "Order Medicines",
          // routePath: `/category/Order-Medicine`//old
          // routePath: `/Order-Medicine`
          routePath: `https://www.healthtunnel.com/category-detail/3`,
        },
        {
          title: "Lab Tests",
          // routePath: '/category/Book-Lab-Test'
          // routePath: '/Book-Lab-Test'
          routePath: "https://www.healthtunnel.com/category-detail/2",
        },
        {
          title: "HT Smart Clinic",
          // routePath: '/category/Emergency-Medical-Loan'
          // routePath: '/Emergency-Medical-Loan'
          routePath: "/",
        },
        // {
        //   title: "Doctor Consultancy",
        //   // routePath: '/category/Online-Doctor-Consultancy'
        //   // routePath: '/Online-Doctor-Consultancy'
        //   routePath:
        //     "https://www.healthtunnel.com/category-detail/consult-doctor-online",
        // },
        // {
        //   title: "Offers",
        //   // routePath: '/category/Top-Fertility-Clinics-in-India'
        //   // routePath: '/Top-Fertility-Clinics-in-India'
        //   routePath: "https://www.healthtunnel.com/offers-page",
        // },
        {
          title: "Videos",
          routePath: "https://www.healthtunnel.com/videos-page",
        },
      ],
    };
  }
  toogleMenu = () => {
    this.setState({
      showHideMenu: !this.state.showHideMenu,
    });
  };

  render() {
    console.log(window.location.pathname);
    return (
      <header
        className={`header-wrapper ${
          !this.props.showMenuItemsCTA && "pt-2 pb-2"
        }`}
      >
        <div className="container-fluid">
          <div className="header-wrapper--logo-menu d-flex align-items-center justify-content-between">
            <div className="icon-logo d-flex align-items-center">
              <NavLink
                to={
                  window.location.pathname === "/atm-book-now"
                    ? "/home"
                    : `https://www.healthtunnel.com/`
                }
                className="logo-wrapper d-flex justify-content-center align-items-center"
              >
                <img
                  src={Sitelogo}
                  className="img-fluid"
                  width="38"
                  height="38"
                  alt="HEALTH TUNNEL"
                />
                <div className="ms-2">
                  <h6 className="bold-font">
                    HEALTH TUNNEL <sup>&#174;</sup>
                  </h6>
                  <p style={{ margin: 0, fontSize: "12px", color: "#423f3f" }}>
                    Well Beyond Healthcare
                  </p>
                </div>
                {/* <div className="logo-wrapper--meta ms-2">
                  <h6 className="bold-font">
                    HEALTH TUNNEL <sup>&#174;</sup>
                  </h6>
                  <p className="small-font d-none-mobile">
                    Well Beyond Healthcare
                  </p>
                </div> */}
              </NavLink>
            </div>
            <div>
              {this.props.showMenuItemsCTA && (
                <nav
                  className={
                    this.state.showHideMenu
                      ? "header-wrapper--menu-wrapper d-none-mobile active"
                      : "header-wrapper--menu-wrapper d-none-mobile"
                  }
                >
                  <NavLink to="/" className="mobile-logo d-none-tablet-above">
                    <div className="logo-wrapper d-flex align-items-center">
                      <img
                        src={Sitelogo}
                        className="img-fluid"
                        width="38"
                        height="38"
                        alt="HEALTH TUNNEL"
                      />
                      <div className="logo-wrapper--meta ml-2">
                        <h6 className="bold-font">
                          HEALTH TUNNEL <sup>&#174;</sup>
                        </h6>
                        <p className="small-font d-none-mobile">
                          Well Beyond Healthcare
                        </p>
                      </div>
                    </div>
                  </NavLink>
                  <a
                    className="close-menu-icon-wrapper d-none-tablet-above"
                    onClick={this.toogleMenu}
                  >
                    <CloseSVG />
                  </a>
                  <ul>
                    {this.state.menuArray.map((menuObj, index) => {
                      if (menuObj.routePath === "/") {
                        return (
                          <li key={index}>
                            <a
                              href={menuObj.routePath}
                              className={
                                menuObj.routePath === window.location.pathname
                                  ? "active"
                                  : ""
                              }
                            >
                              <b>{menuObj.title}</b>
                            </a>
                          </li>
                        );
                      } else {
                        return (
                          <li key={index}>
                            <a
                              // onClick={()=>this.handleRedirect(menuObj.routePath)}
                              className={
                                menuObj.routePath === window.location.pathname
                                  ? "active"
                                  : ""
                              }
                              href={`${menuObj.routePath}`}
                            >
                              <b>{menuObj.title}</b>
                            </a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </nav>
              )}
            </div>
            <div></div>
            <div></div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
